import { ref } from 'vue';

export default function useInput() {
  //
  // Props
  //

  const getDefaultProps = () => ({
    autocomplete: {
      type: String,
      default: 'off',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    canTrimValue: {
      type: Boolean,
      default: false,
    },
    dataLpignore: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideEmptyErrors: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    resetOnFocus: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  });

  //
  // Refs
  //

  const input = ref(null);
  const inputValue = ref('');

  //
  // Computed Properties
  //

  const computedInputAttrs = (myProps, additionalNonAttrProps = []) => () => {
    return Object.keys(myProps).reduce((attrs, key) => {
      const nonAttrProps = [
        'focus',
        'fullWidth',
        'hideEmptyErrors',
        'inputClass',
        'label',
        'modelValue',
        'resetOnFocus',
        'rules',
        ...additionalNonAttrProps,
      ];

      if (nonAttrProps.includes(key)) {
        // Skip these properties. They are not used as attributes on the <input>.
        return attrs;
      }

      if (key === 'dataLpignore') {
        // This prop needs to be manually renamed to data-lpignore. It's used to disable the
        // LastPass password manager.
        attrs['data-lpignore'] = myProps[key];
      }
      else {
        attrs[key] = myProps[key];
      }

      return attrs;
    }, {});
  };

  const computedIsRequired = (myProps) => () => {
    return myProps.required || myProps.rules.includes('required');
  };

  const computedRules = (myProps) => () => {
    if (myProps.required && !myProps.rules.includes('required')) {
      const rules = myProps.rules.split('|');
      rules.splice(0, 0, 'required');
      return rules.join('|');
    }

    return myProps.rules;
  };

  //
  // Watches
  //

  const watchFocus = (value) => {
    if (value === true && input.value) {
      input.value.focus();
    }
    else if (value === false && input.value) {
      input.value.blur();
    }
  };

  const watchModelValue = (newValue) => {
    inputValue.value = newValue;
  };

  //
  // Life Cycle Hooks
  //

  const handleOnFocus = (shouldResetOnFocus, resetField) => {
    if (shouldResetOnFocus) {
      return resetField;
    };

    return () => { /* intentionally empty */ };
  };

  const handleOnMounted = (myProps = {}) => {
    if (myProps.autofocus && input.value) {
      input.value.focus();
    }
  };

  return {
    getDefaultProps,
    handleOnMounted,
    handleOnFocus,
    computedInputAttrs,
    computedIsRequired,
    computedRules,
    input,
    inputValue,
    watchFocus,
    watchModelValue,
  };
}
